import React, { Component } from "react";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Component
import Input, { InputGroup } from "../../../components/uielements/input";
import Select from "../../../components/uielements/select";
import Form from "../../../components/uielements/form";
import SignInStyleWrapper from "./style";
import * as actions from "../../../redux/actions";
import { validateField, validateForm } from "../../../helpers/validation";
import { COUNTRY_CODE, APP_ENV, APP_VERSION } from "../../../settings";
import Modals from "../../../components/feedback/modal";
import Icon from "../../../assets/components/Icon";
import Color from "../../../assets/Color";

import sendSMS from "../../../assets/icon/sendSMS.png";
import moment from "moment";

// UI Component

const FormItem = Form.Item;


class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        countryCode: {
          value: "+60",
          validation: {
            rules: {
              required: true
            }
          },
          valid: true
        },
        phoneNo: {
          value: "",
          valid: true,
          validation: {
            rules: {},
            error_message: {}
          }
        },
        veri_code: {
          value: "",
          valid: true,
          validation: {
            rules: {},
            error_message: {}
          }
        },
      },
      error_message: {
        countryCode: "",
        phoneNo: "",
        veri_code: ""
      },
      step: "sendSMS",
      nextRequestTime: null,
      timer: 60,
      timeout: false,
      timeoutOn: null
    };

    // this.myRef = React.forwardRef();
    
  }
  
  
  componentDidUpdate(prevProps, nextProps) {
    
    const { isSMSSendSuccess, accPendingApproval } = this.props; 
    const { step } = this.state;

    if(
      JSON.stringify(prevProps.isSMSSendSuccess) !==  JSON.stringify(isSMSSendSuccess) &&
      isSMSSendSuccess
    ){

      this._startTimer();

      if(step !== "verifySMS")
      {      
        this.setState({step: "verifySMS"})
      }
    }

    if(
      JSON.stringify(prevProps.accPendingApproval) !==  JSON.stringify(accPendingApproval) &&
      accPendingApproval
    ){
      
      this._accPendingApproval(accPendingApproval);
    }
        
  }

  _proceedBack = () => {
    
    let fields = cloneDeep(this.state.fields);

    fields["veri_code"].value = ""

    this.setState({fields, step: "sendSMS"})
  }

  _startTimer = (timers = 60) => {

    var timeoutOn = moment().add(timers, "s").format("YYYY-MM-DD HH:mm:ss");

    this.setState( { timeout: false, timer: timers, timeoutOn }, () => {
        this.timer = setInterval(() => {

          this.setState(
            (prevState) => ({
              timer: prevState.timer - 1
            }),
            () => {
              if (this.state.timer <= 0) {
                this._stopTimer();
              }
            }
          );
        }, 1000);
      }
    );
  };

  _stopTimer = () => {
    this.setState( { timer: 0, timeout: true, timeoutOn: null }, () => {
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    );
  };

  _onSubmit = async () => {
    
    const { step }  = this.state

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let validator = await validateForm(fields, error_message);


    this.setState({ ...validator.data }, () => {
      if (!validator.valid) {
        this._handleInvalidForm();
      } else {
        let countryCode = fields.countryCode.value;

        let params = {
          contact_no: countryCode + fields.phoneNo.value,
          oper: "login"
        };

        if(step === "sendSMS")        
          this.props.onSendSMS(params);
        
      }
    });
  };

  _resendSMS = () => {

    let fields = cloneDeep(this.state.fields);

    let countryCode = fields.countryCode.value;

    let params = {
      contact_no: countryCode + fields.phoneNo.value,
      oper: "login"
    };

    this.props.onSendSMS(params);
  }
  

  _accPendingApproval = (data) => {
   
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
          
    const handleOk = () => {

      fields.veri_code.value = "";
      error_message.veri_code = ""
      
      this.setState({fields, error_message, step: "sendSMS"})
    };
      
    Modals.error({
      title: data.title,
      content: data.msg,
      onOk() {
        handleOk();
      },
      okText: "OK",
    });

  }

  _onChangeText = (name, value) => {
    let fields = cloneDeep(this.state.fields);

    if(value.length <= 10)
      fields[name].value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

    this.setState({ fields });
  };


  _onInsertVeriCode = (value) => {

    let fields = cloneDeep(this.state.fields);
 
    if(value.length <= 4)
      fields["veri_code"].value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    
    this.setState({ fields }, () => {
      
      if(fields["veri_code"].value.length === 4)
      {      
        let countryCode = fields.countryCode.value;

        let params = {
          contact_no: countryCode + fields.phoneNo.value,
          oper: "login",
          veri_code: value
        };

        this.props.onVerifySMS(params);
      }
    });

  };

  _onHandleBlurr = e => {
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let name = "";

    if (typeof e === "string") {
      name = e;
    } else {
      name = e.target.name;
    }

    let validator = validateField(fields, name, error_message);

    this.setState({ ...validator });
  };

  _handleInvalidForm = () => {
    let fields = cloneDeep(this.state.fields);
    Object.keys(fields).some(name => {
      if (!fields[name].valid) {
        return true;
      }
      return false;
    });
  };

  render() {
    const { smsCode, isSMSSendFail, isVerifySMSFail, verifySMSErrorMsg, sendSMSErrorMsg }  = this.props
    const { fields, step, timer }  = this.state

    const countryCode = COUNTRY_CODE.map((codes, key) => (
      <option key={codes.dial_code}>{codes.dial_code}</option>
    ));

    let err_msg = ""

    if(step === "sendSMS" && isSMSSendFail && sendSMSErrorMsg)
    {
      err_msg = sendSMSErrorMsg

      if(typeof sendSMSErrorMsg === "object" && sendSMSErrorMsg.length > 0)    
        err_msg = sendSMSErrorMsg[0].msg
    }

    if(step === "verifySMS" && isVerifySMSFail && verifySMSErrorMsg)
    {
      err_msg = verifySMSErrorMsg

      if(typeof verifySMSErrorMsg === "object" && verifySMSErrorMsg.length > 0)    
        err_msg = verifySMSErrorMsg[0].msg
    }

    return (
      <SignInStyleWrapper className="isoSignInPage">
        <div>
          <div className="isoLoginContentWrapper">

            {step === "verifySMS" && (

              <div className="pg-header">
                <div 
                  className="backBtn"
                  onClick={() => this._proceedBack()}
                >
                  <Icon
                    type="custom"
                    name="back"
                    color={Color.Black}
                    size={15}
                  />
                </div>
              </div>
            )} 


            <div className={`isoLoginContent ${step === "verifySMS" ? "pt-0" : ""}`}>

              {step === "sendSMS" && (
                
                <React.Fragment>
                  <div className="color-primary font-bold font-30">Welcome User!</div>
                  
                  <div className="mt-1 font-17">Please proceed with your mobile number to Login.</div>

                  <div className="isoSignInForm mt-4">
                    <Form layout="vertical">

                      <div className="isoInputWrapper">
                        <InputGroup compact>
                          <FormItem className="width30">
                            <Select
                              size="large"
                              disabled={true}
                              value={this.state.fields["countryCode"].value}
                            >
                              {countryCode}
                            </Select>
                          </FormItem>
                          <FormItem className="width68 ml2">
                            <Input
                              size="large"
                              type="text"
                              className="full-width"
                              // maxLength="10"
                              pattern="[0-9]+" 
                              autoComplete="off"
                              value={this.state.fields["phoneNo"].value}
                              onChange={event =>
                                this._onChangeText("phoneNo", event.target.value)
                              }
                              name="phoneNo"
                              onBlur={this._onHandleBlurr}
                            />
                          </FormItem>
                        </InputGroup>
                      </div>

                      {isSMSSendFail && sendSMSErrorMsg && (

                        <div className="text-danger">{err_msg}</div>
                      )}
                      
                      {this.state.fields["phoneNo"].value.length > 8 && (

                        <div 
                          className={`isoInputWrapper isoLeftRightComponent ${isSMSSendFail ? "pt-3" : "pt-4"}`}
                          onClick={() => this._onSubmit()}
                        >
                          PROCEED
                        </div>
                      )}

                    </Form>
                  </div>

                </React.Fragment>
              )}

                          
              {step === "verifySMS" && (
                <React.Fragment>
                  <div className="img-verify">
                    <div className="isoImgWrapper">
                      <img alt=""  src={sendSMS}/>
                    </div>
                  </div>

                  <div className="text-center font-17">We have sent you a code to verify your mobile phone number...</div>

                  <div className="mt-3 text-center color-primary font-bold font-25">({fields.countryCode.value}) {fields.phoneNo.value}</div>

                  <div className="isoSignInForm mt-4">
                    <Form layout="vertical">

                      <div className="isoInputWrapper">                     
                        <FormItem>
                          <Input
                            size="large"
                            // ref={this.myRef} 
                            type="text"
                            className="full-width"
                            // maxLength="4"
                            placeholder="Enter Verification Code that you received..." 
                            pattern="[0-9]+" 
                            autoComplete="off"
                            value={this.state.fields["veri_code"].value}
                            onChange={event =>
                              this._onInsertVeriCode(event.target.value)
                            }
                            name="veri_code"
                          />
                        </FormItem>
                      </div>
                      <div className="mt-2">{smsCode}</div>

                      {isVerifySMSFail && verifySMSErrorMsg && (

                        <div className="text-danger">{err_msg}</div>
                      )}
                      {/* <div className="isoInputWrapper">
                        <FormItem
                          label="Password"
                          validateStatus={
                            !this.state.fields["password"].valid ? "error" : ""
                          }
                          help={this.state.error_message["password"]}
                        >
                          <Input
                            type="password"
                            placeholder="Password"
                            value={this.state.fields["password"].value}
                            onChange={event =>
                              this._onChangeText("password", event.target.value)
                            }
                            name="password"
                            onBlur={this._onHandleBlurr}
                          />
                        </FormItem>
                      </div> */}

                    </Form>
                  </div>

                  <div className="mt-5 text-center">
                    <div className="font-17 mb-2">Didn't receive verification code?</div>

                    {timer <= 0 ? (

                      <div
                        className="color-primary font-17"
                        onClick={() => this._resendSMS()}
                      >
                        Request new code
                      </div>

                    ):(
                        <div className="color-grey font-17">Request new code in {timer}s</div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          {/* {step === "sendSMS" && (

            <div className="bottomBackground isoImgWrapper">
              <img alt="" src={background}/>
            </div>
          )} */}
          {step === "sendSMS" && (
            <div className="text-center pt-3 bottomText b15 color-white">V{APP_VERSION} - {(APP_ENV) ? APP_ENV.slice(0,1).toUpperCase(): ""}</div>
          )}
        </div>
        <ToastContainer autoClose={2000} />
      </SignInStyleWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { isLoggedIn, profileData, isSMSSendSuccess, smsCode, sendSMSErrorMsg, isSMSSendFail, isVerifySMSFail, verifySMSErrorMsg, accPendingApproval } = state.Auth;

  return { isLoggedIn, profileData, isSMSSendSuccess, smsCode, sendSMSErrorMsg, isSMSSendFail, isVerifySMSFail, verifySMSErrorMsg, accPendingApproval };

};

const mapDispatchToProps = dispatch => {
  return {
    
    onSendSMS: params => dispatch(actions.sendSMS(params)),
    onVerifySMS: params => dispatch(actions.verifySMS(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(SignIn);
