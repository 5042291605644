import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/user";
  // return "http://192.168.1.12:3301/grader"
};

export const deviceRegister = (data) => {
  const url = `${baseURL()}/deviceRegister`;
  return axios.post(url, data);
};

export const sendSMS = data => {
  const url = `${baseURL()}/sms/send`;
  return axios.post(url, data);
};

export const verifySMS = data => {
  const url = `${baseURL()}/sms/verify`;
  return axios.post(url, data);
};

export const firstLogin = data => {
  const url = `${baseURL()}/firstLogin`;
  return axios.post(url, data);
};

export const signUp = data => {
  const url = `${baseURL()}/signup`;
  return axios.post(url, data);
};

export const validateVersion = data => {
  const url = `${baseURL()}/version`;
  return axios.post(url, data);
};

export const verifyAccount = data => {
  const url = `${baseURL()}/verify/account`;
  return axios.post(url, data);
};

export const getAccountVerifyStatus = data => {
  const url = `${baseURL()}/verify/account/status`;
  return axios.post(url, data);
};

export const getMyProfile = () => {
  const url = `${baseURL()}/detail`;
  return axios.get(url);
};

export const updateProfile = data => {
  const url = `${baseURL()}/detail/update`;
  return axios.post(url, data);
};

export const uploadProfilePic = data => {
  const url = `${baseURL()}/profile/upload`;
  return axios.post(url, data);
};

export const delProfilePic = data => {
  const url = `${baseURL()}/profile/remove`;
  return axios.post(url, data);
};

export const logout = () => {
  const url = `${baseURL()}/logout`;
  return axios.get(url);
};

export const refreshJWTToken = (data) => {
  const url = `${baseURL()}/token`;
  return axios.post(url, data);
};

export const updatePhone = data => {
  const url = `${baseURL()}/updatePhone`;
  return axios.post(url, data);
};




export const login = data => {
  const url = `${baseURL()}/login`;
  return axios.post(url, data);
};

export const checkVersion = data => {
  const url = `${baseURL()}/webVersion`;
  return axios.post(url, data);
};

export const versionUpdateList = data => {
  const url = `${baseURL()}/version/list`;
  return axios.post(url, data);
};
