import { all } from "redux-saga/effects";
import authSagas from "./auth_saga";
import appSagas from "./app_saga";
import googleMapSaga from "./google_map_saga";
import generalSaga from "./general_saga";
import orderSaga from "./order_sage";

export default function* rootSaga() {
  yield all([
    appSagas(), 
    authSagas(), 
    googleMapSaga(),
    generalSaga(),
    orderSaga(),
  ]);
}
