import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/user/express";
  // return "http://192.168.1.16:3004/user/express"
};

export const generateQR = data => {
  const url = `${baseURL()}/generateQR`;
  return axios.post(url, data);
};

export const recentExpressOrder = (data) => {
  const url = `${baseURL()}/recentExpressOrder`;
  return axios.post(url, data);
};

export const expressOrderDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};


export const expressConfirmOrder = (id, data) => {
  const url = `${baseURL()}/complete/${id}`;
  return axios.post(url, data);
};
