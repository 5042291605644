import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "../../../settings/withDirection";
import Color from "../../../assets/Color";
import { borderRadius } from "../../../settings/style-util";
import background from "../../../assets/img/signup-background.png";

const SignInStyleWrapper = styled.div`
  border:1px solid rgb(255 255 255 / 0%);
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.Sand};

  .backgroundImg {

    background: url(${background}) no-repeat center bottom;
    width: 100%;
    min-height: 95vh;
    background-size: contain;
  }
  

  .pg-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; 
    padding: 30px;
  }

  .font-17 {
    font-size: 17px;
    line-height: 1.2;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-20 {
    font-size: 20px;
  }

  .font-25 {
    font-size: 25px;
  }

  .font-30 {
    font-size: 30px;
  }

  .font-35 {
    font-size: 35px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-13 {
    font-size: 13px;
  }

  .font-11 {
    font-size: 11px;
  }

  .font-bold {
    font-weight: bold;
  }

  .color-grey {    
    color: ${Color.Nobel};
  }

  .color-darkgrey {    
    color: ${Color.DustyGrey};
  }

  .color-primary {    
    color: ${Color.Secondary};
  }


  .ant-input-group .ant-input{
    text-align: left;
  }
 
  .ant-input-group{
    margin-bottom: 0px;

    .ant-select{
      width: 100%
    }
  }


  .isoImgWrapper {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .bottomBackground {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .bottomText{
    position: fixed;
    bottom: 100px;
    left: 0;
    width: 100%;
  }

  .isoLoginContentWrapper {
    margin: 40px 10px 0 10px;
  }

  .isoLoginContent {
    font-size: 19px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    position: relative;

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 30px 20px;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      margin-bottom: 25px;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette("secondary", 2)};
      }
    }

    .img-verify {
      text-align: -webkit-center;
      
      .isoImgWrapper {
        width: 200px;
        height: 230px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .isoSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      .isoInputWrapper {
        margin-bottom: 10px;
        display: block;
        &:last-of-type {
          margin-bottom: 0;
        }

        .ant-input-group{
          margin-bottom: 0px;

          .ant-select{
            width: 100%
          }
        }

        .ant-form-item{
          margin-bottom: 0px;
          padding-bottom:0;

          .ant-form-item-label label{
            font-size: 10px;
          }
          input {
            &::-webkit-input-placeholder {
              color: ${palette("grayscale", 0)};
            }

            &:-moz-placeholder {
              color: ${palette("grayscale", 0)};
            }

            &::-moz-placeholder {
              color: ${palette("grayscale", 0)};
            }
            &:-ms-input-placeholder {
              color: ${palette("grayscale", 0)};
            }
          }
        }

        input[type="password"]{
          width: 100%
        }
        }

      .isoHelperText {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: ${palette("grayscale", 1)};
        padding-left: ${props =>
          props["data-rtl"] === "rtl" ? "inherit" : "13px"};
        padding-right: ${props =>
          props["data-rtl"] === "rtl" ? "13px" : "inherit"};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: "*";
          color: ${palette("error", 0)};
          padding-right: 3px;
          font-size: 14px;
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          right: ${props => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoHelperWrapper {
        margin-top: 25px;
        flex-direction: column;

        .isoForgotPass{
          cursor: pointer;
            font-size:12px;
            text-transform: uppercase;
            color: ${palette("text", 3)};
            &:hover{
              color:${Color.SoftOrange}
            }
        }
      }

      .isoOtherLogin {
        padding-top: 40px;
        margin-top: 35px;
        border-top: 1px dashed ${palette("grayscale", 2)};

        > a {
          display: flex;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          height: 42px;
          border: 0;
          font-weight: 500;


          &.btnFacebook {
            background-color: #3b5998;

            &:hover {
              background-color: darken(#3b5998, 5%);
            }
          }

          &.btnGooglePlus {
            background-color: #dd4b39;
            margin-top: 15px;

            &:hover {
              background-color: darken(#dd4b39, 5%);
            }
          }

          &.btnAuthZero {
            background-color: #e14615;
            margin-top: 15px;

            &:hover {
              background-color: darken(#e14615, 5%);
            }
          }

          &.btnFirebase {
            background-color: ${palette("color", 5)};
            margin-top: 15px;

            &:hover {
              background-color: ${palette("color", 6)};
            }
          }
        }
      }

     .width30{
       width: 30%
     }

     .width68{
      width: 68%
     }

     .ml2{
       margin-left: 2%;
     }

     .mt25px{
      margin-top: 5%;
     }
    }
  }

  
  /*===== Button Bottom =====*/

  .buttonBottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: ${Color.White};
    padding: 25px;
    text-align: center;
    z-index: 100;
    border-radius: 30px 30px 0 0;
    -webkit-box-shadow: 0px -4px 3px #fafafa;
    -moz-box-shadow: 0px -4px 3px #fafafa;
    box-shadow: 0px -4px 3px #fafafa;

    button {
      width: 100%;
    }

    .btn-1, .btn-2 {
      width: 50%;
    }
  }
  
  .width100 button{
    width: 100%;
  }

  .bottom-btn {
    border-radius: 10px;
    text-transform: capitalize !important;
    height: 55px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .bottom-btn.grey-btn {  
    background: ${palette("grayscale", 12)};
    color: ${palette("grayscale", 13)};
  }

  .bottom-btn.dark-btn {
    background: ${Color.Salem};
    color: ${Color.White};
  }

  .bottom-btn.primary-btn {
    background: ${Color.Primary};
    color: ${Color.White};
  }

  .bottom-btn.green-btn {
    background: ${palette("grayscale", 13)};
    color: ${Color.White};
  }

  .bottom-btn.orange-btn {
    background: ${palette("grayscale", 14)};
    color: ${Color.White};
  }

  .view-doc {
    padding: 0 35px !important;
    height: 45px !important;
  }

  .addBtnBackgroud {
    background: ${Color.Primary};    
    ${borderRadius("50%")};
    padding: 0 5px;
    text-align: center;
  }
  /*===== End Button Bottom =====*/

  /*===== Modal Wrap =====*/

  .ant-modal-wrap {
    top: unset;
  }

  .ant-modal {
    top: unset;
    bottom: 0;
  }

  .ant-modal-content {
    border-radius: 15px;
  }
  /*===== End Modal Wrap =====*/
`;

export default WithDirection(SignInStyleWrapper);
