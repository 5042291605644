import React, { Component } from "react";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  /* ===== General ===== */
  {
    path: "loading",
    component: asyncComponent(() => import("../Page/LoadingPage"))
  },
  {
    path: "signin",
    component: asyncComponent(() => import("../Page/SignIn"))
  },
  {
    path: "",
    component: asyncComponent(() => import("../Page/Home"))
  },
  {
    path: "blank",
    component: asyncComponent(() => import("../Page/Blank"))
  },
  /* ===== END General ===== */

  {
    path: "DumpExpress",
    component: asyncComponent(() => import("../Page/DumpExpress"))
  },  
  {
    path: "profile",
    component: asyncComponent(() => import("../Page/Profile"))
  },
  
];

class AppRouter extends Component {
  render() {
    const { url, style, profileData } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(data => {
            const { path, exact, ...otherProps } = data;

            let havePermission = true;
            if (data.permission) {
              if (profileData[data.permission] === false) {
                havePermission = false;
              }
            }

            return havePermission ? (
              <Route
                exact={exact === false ? false : true}
                key={data.path}
                path={`${url}${data.path}`}
                {...otherProps}
              />
            ) : null;
          })}
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AppRouter);
