import validator from "validator";

const validateField = (fields, name, error_message) => {
  let value = fields[name].value;

  if (JSON.stringify(fields[name].validation) !== JSON.stringify({})) {
    let validator = fields[name].validation.rules.equalTo
      ? validation(
          fields[name].validation,
          value,
          fields[fields[name].validation.rules.equalTo].value
        )
      : validation(fields[name].validation, value);

    fields[name].valid = validator.valid;
    !validator.valid
      ? (error_message[name] = validator.message)
      : (error_message[name] = "");
  }

  let data = {
    fields: fields,
    error_message: error_message
  };
  return data;
};

const validateForm = (fields, error_message) => {
  return new Promise(res => {
    let validator = null;
    let valid = true;
    let data = {
      valid,
      data: {
        fields,
        error_message
      }
    };

    Object.keys(fields).map(name => {
      validator = validateField(fields, name, error_message);
      fields = validator.fields;
      error_message = validator.error_message;
      valid = fields[name].valid && valid;

      data = {
        valid,
        data: {
          fields,
          error_message
        }
      };

      return data;
    });

    res(data);
  });
};

const validation = (validation, value, value2 = null) => {
  let isValid = true;
  let response = { valid: true, message: "" };

  let rules = validation.rules;
  let message = validation.error_message ? validation.error_message : false;

  if (!rules) {
    return response;
  }

  if (rules.required) {
    switch (typeof value) {
      case "boolean":
        isValid = value;
        break;
      case "object":
        if (value === null) {
          isValid = false;
        } else if (JSON.stringify(value) === JSON.stringify({})) {
          isValid = false;
        } else if (JSON.stringify(value) === JSON.stringify([])) {
          isValid = false;
        } else if (value === undefined) {
          isValid = value.name !== undefined;
        } else if (Array.isArray(value)) {
          if (value.length === 0) {
            isValid = value.length !== 0;
          }
        } else if (value.name !== undefined) {
          isValid = value.name !== undefined;
        } else {
        }
        break;
      case "number":
        isValid = value !== "";
        break;
      default:
        isValid = value.trim() !== "";
        break;
    }

    if (!isValid) {
      response.valid = isValid;
      response.message = message.required
        ? message.required
        : "This field is required.";
      return response;
    }
  } else {
    switch (typeof value) {
      case "boolean":
        isValid = value;
        break;
      case "object":
        if (value === null) {
          isValid = true;
        } else if (JSON.stringify(value) === JSON.stringify({})) {
          isValid = true;
        } else if (JSON.stringify(value) === JSON.stringify([])) {
          isValid = true;
        } else if (value === undefined) {
          isValid = value === undefined;
        } else if (Array.isArray(value)) {
          if (value.length === 0) {
            isValid = value.length === 0;
          }
        } else if (value.name === undefined) {
          isValid = value.name === undefined;
        } else {
          isValid = value.name === undefined;
        }
        break;
      case "number":
        isValid = value === "";
        break;
      default:
        isValid = value.trim() === "";
        break;
    }

    if (isValid) {
      if (validation.additional) {
        Object.keys(validation.additional).forEach(function(key) {
          isValid = validation.additional[key].rules(value);

          if (!isValid) {
            response.valid = isValid;
            response.message = validation.additional[key].error_message
              ? validation.additional[key].error_message
              : "Invalid field.";
          }
        });
      }
      return response;
    }
  }

  if (rules.isEmail) {
    // const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    // isValid = pattern.test(value);
    isValid = validator.isEmail(value);

    if (!isValid) {
      response.valid = isValid;
      response.message = message.isEmail
        ? message.isEmail
        : "Please enter a valid email address.";
      return response;
    }
  }

  if (rules.isURL) {
    // eslint-disable-next-line
    const pattern = /((http|https)?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-zA-Z0-9\d%_.~#!:.?+=&%-+]*)*(\?[;&a-zA-Z0-9\\d%_.~#!:.?+=&%-+]*)?(\#[-a-zA-Z0-9\\d_]*)?$/;
    isValid = pattern.test(value);

    if (!isValid) {
      response.valid = isValid;
      response.message = message.isURL
        ? message.isURL
        : "Please enter a valid URL.";
      return response;
    }
  }

  if (rules.isDigits) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value);

    if (!isValid) {
      response.valid = isValid;
      response.message = message.isDigits
        ? message.isDigits
        : "Please enter only digits.";
      return response;
    }
  }

  if (rules.isNumber) {
    const pattern = /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/;
    isValid = pattern.test(value);

    if (!isValid) {
      response.valid = isValid;
      response.message = message.isNumber
        ? message.isNumber
        : "Please enter a valid number.";
      return response;
    }
  }

  if (rules.isCurrency) {
    // var regex = /^\d+(\.\d{1,2})?$/;
    var regex = /^(?:0|[1-9][0-9]*)(?:\.[0-9]{1,2})?$/;
    isValid = regex.test(value);

    if (!isValid) {
      response.valid = isValid;
      response.message = message.isCurrency
        ? message.isCurrency
        : "Please enter valid price.";
      return response;
    }
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength;

    if (!isValid) {
      response.valid = isValid;
      response.message = message.minLength
        ? message.minLength.replace("{{value}}", rules.minLength)
        : `Please enter at least ${rules.minLength} characters.`;
      return response;
    }
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength;

    if (!isValid) {
      response.valid = isValid;
      response.message = message.maxLength
        ? message.maxLength.replace("{{value}}", rules.maxLength)
        : `Please enter no more than ${rules.maxLength} characters.`;
      return response;
    }
  }

  if (rules.min) {
    isValid = value >= rules.min;

    if (!isValid) {
      response.valid = isValid;
      response.message = message.min
        ? message.min.replace("{{value}}", rules.min)
        : `Please enter a value greater than or equal to ${rules.min}.`;
      return response;
    }
  }

  if (rules.max) {
    isValid = value <= rules.max;

    if (!isValid) {
      response.valid = isValid;
      response.message = message.max
        ? message.max.replace("{{value}}", rules.max)
        : `Please enter a value less than or equal to ${rules.max}.`;
      isValid = value <= rules.min;
      return response;
    }
  }

  if (rules.equalTo) {
    isValid = value === value2;

    if (!isValid) {
      response.valid = isValid;
      response.message = message.equalTo
        ? message.equalTo
        : "Please enter the same value again.";
      return response;
    }
  }

  if (validation.additional) {
    Object.keys(validation.additional).forEach(function(key) {
      isValid = validation.additional[key].rules(value);

      if (!isValid) {
        if (response.valid) {
          response.valid = isValid;
          response.message = validation.additional[key].error_message
            ? validation.additional[key].error_message
            : "Invalid field.";

          return response;
        }
      }
    });
  }

  return response;
};

export default validation;
export { validateField, validateForm };
