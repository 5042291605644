import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";

export function* loadGooglePlaceAutocomplete() {
  
  yield takeEvery(actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE, function*(action) {
    //yield put(actions.enableLoading());

    try {
      const response = yield API.loadGooglePlaceAutocomplete(action.data);
      if(response)
      {
        if (response.data.status === "OK") {
          yield put(
            actions.loadGooglePlaceAutocompleteSuccess(response.data.predictions)
          );
        } else {
          // alert(response.data.status);
          yield put(actions.loadGooglePlaceAutocompleteFail());
        }
      }
      //yield put(actions.disableLoading());
    } catch (error) {
      console.log(error, "Fail to get autocomplete list.");
      yield put(actions.loadGooglePlaceAutocompleteFail());
     // yield put(actions.disableLoading());
    }
  });
}

export default function* googleMapSaga() {
  yield all([fork(loadGooglePlaceAutocomplete)]);
}
