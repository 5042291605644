import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const INITIAL_STATE = {
  autocompleteList: {
    data: [],
    isLoading: false
  }
};

export default function googleMapReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE: {
      let autocompleteList = cloneDeep(state.autocompleteList);
      autocompleteList.data = [];
      autocompleteList.isLoading = true;
      return { ...state, autocompleteList: autocompleteList };
    }
    case actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS: {
      let autocompleteList = cloneDeep(state.autocompleteList);
      autocompleteList.data = action.data;
      autocompleteList.isLoading = false;
      return {
        ...state,
        autocompleteList: autocompleteList
      };
    }
    case actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL: {
      let autocompleteList = cloneDeep(state.autocompleteList);
      autocompleteList.isLoading = false;
      return {
        ...state,
        autocompleteList
      };
    }
    default:
      return state;
  }
}
