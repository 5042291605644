import styled from "styled-components";
import WithDirection from "../../../settings/withDirection";
import background from "../../../assets/img/under-maintenance2.jpg";

const CustomStyles = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: center; 
  
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  min-height: 80vh;
  object-fit: fill;

`;

export default WithDirection(CustomStyles);
