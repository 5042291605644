import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  
  isProcessGenerateQR: false,
  isGenerateQRSuccess: false,
  isGenerateQRFail: false,
  
  recentOrder: {},
  recentOrderBF: {},
  isLoadRecentOrderSuccess: false,

  expressOrderDetail: {},
  expressOrderDetailBF: {},
  isProcessOrderDetail: false,
  
  isOrderConfirmSuccess: false,
  isOrderConfirmFailed: false,
  isProcessConfirmOrder: false,
};

export default function OrderReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.GENERATE_USER_QR: {
      return {
        ...state,
        isProcessGenerateQR: true,
        isGenerateQRSuccess: false,
        isGenerateQRFail: false,
      };
    }

    case actionTypes.GENERATE_USER_QR_SUCCESS: {
      
      return {
        ...state,
        isProcessGenerateQR: false,
        isGenerateQRSuccess: true,
      };
    }

    case actionTypes.GENERATE_USER_QR_FAIL: {
      return {
        ...state,
        isProcessGenerateQR: false,
        isGenerateQRFail: true,
      };
    }
    
    
    case actionTypes.RECENT_EXPRESS_ORDER: {
      return {
        ...state,
        recentOrder: {},
        isLoadRecentOrderSuccess: false,
      };
    }
    
    case actionTypes.RECENT_EXPRESS_ORDER_DUPLICATE_DATA: {
      return {
        ...state,
        recentOrder: cloneDeep(state.recentOrderBF),
        isLoadRecentOrderSuccess: true,
      };
    }

    case actionTypes.RECENT_EXPRESS_ORDER_SUCCESS: {
      return {
        ...state,
        recentOrder: action.data,
        recentOrderBF: action.data,
        isLoadRecentOrderSuccess: true,
      };
    }

    case actionTypes.RECENT_EXPRESS_ORDER_FAIL: {
      return {
        ...state,
        isLoadRecentOrderSuccess: false,
      };
    }


    case actionTypes.EXPRESS_ORDER_DETAIL: {
      return {
        ...state,
        expressOrderDetail: {},
        isProcessOrderDetail: true,
      };
    }
    
    case actionTypes.EXPRESS_ORDER_DETAIL_DUPLICATE_DATA: {
      return {
        ...state,
        expressOrderDetail: cloneDeep(state.expressOrderDetailBF),
        isProcessOrderDetail: false,
      };
    }

    case actionTypes.EXPRESS_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        expressOrderDetail: action.data,
        expressOrderDetailBF: action.data,
        isProcessOrderDetail: false,
      };
    }

    case actionTypes.EXPRESS_ORDER_DETAIL_FAIL: {
      return {
        ...state,
        isProcessOrderDetail: false,
      };
    }

    

    case actionTypes.EXPRESS_CONFIRM_ORDER: {
      return {
        ...state,
        isOrderConfirmSuccess: false,
        isOrderConfirmFailed: false,
        isProcessConfirmOrder: true,
      };
    }

    case actionTypes.EXPRESS_CONFIRM_ORDER_SUCCESS: {
      return {
        ...state,
        isOrderConfirmSuccess: true,
        isProcessConfirmOrder: false,
        expressOrderDetail: {},
      };
    }

    case actionTypes.EXPRESS_CONFIRM_ORDER_FAIL: {
      return {
        ...state,
        isOrderConfirmFailed: true,
        isProcessConfirmOrder: false,
      };
    }
    
    
    default:
      return state;
  }
}
