import React, { Component } from "react";
import CustomStyles from "./style";

export default class extends Component {
  render() {
    return (
      <CustomStyles>
        <div className="maintenance-container"></div>
        
      </CustomStyles>
    );
  }
}
