import * as actionTypes from "./actionTypes";


export const update_device_uuid = (uuid) => {
  return {
    type: actionTypes.SET_DEVICE_UUID,
    uuid
  };
};

export const underMaintenance = data => {
  return {
    type: actionTypes.SET_UNDER_MAINTENANCE,
    data
  };
};


export const proceedSignUp = data => {
  return {
    type: actionTypes.PROCEED_SIGNUP,
    data
  };
};


export const proceedFirstLogin = data => {
  return {
    type: actionTypes.PROCEED_FIRST_LOGIN,
    data
  };
};


export const deviceRegister = (data) => {
  return {
    type: actionTypes.DEVICE_REGISTER,
    data
  };
};

export const deviceRegisterSuccess = (data) => {
  return {
    type: actionTypes.DEVICE_REGISTER_SUCCESS,
    data
  };
};

export const deviceRegisterFail = () => {
  return {
    type: actionTypes.DEVICE_REGISTER_FAIL
  };
};


export const sendSMS = data => {
  return {
    type: actionTypes.SEND_SMS,
    data
  };
};

export const sendSMSSuccess = (data) => {
  return {
    type: actionTypes.SEND_SMS_SUCCESS,
    data
  };
};

export const sendSMSFail = err_msg => {
  return {
    type: actionTypes.SEND_SMS_FAIL,
    err_msg
  };
};


export const verifySMS = data => {
  return {
    type: actionTypes.VERIFY_SMS,
    data
  };
};

export const verifySMSSuccess = (token, refresh_token, profile) => {
  return {
    type: actionTypes.VERIFY_SMS_SUCCESS,
    token,
    refresh_token, 
    profile
  };
};

export const verifySMSFail = err_msg => {
  return {
    type: actionTypes.VERIFY_SMS_FAIL,
    err_msg
  };
};

export const verifySMSPendingApproval = data => {
  return {
    type: actionTypes.VERIFY_SMS_PENDING_APPROVAL,
    data
  };
};


export const loginSuccess = (token, refresh_token, profile) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token,
    refresh_token, 
    profile
  };
};


export const firstLogin = data => {
  return {
    type: actionTypes.FIRST_LOGIN,
    data
  };
};

export const firstLoginSuccess = (token, refresh_token, profile) => {
  return {
    type: actionTypes.FIRST_LOGIN_SUCCESS,
    token,
    refresh_token, 
    profile
  };
};

export const firstLoginFail = err_msg => {
  return {
    type: actionTypes.FIRST_LOGIN_FAIL,
    err_msg
  };
};



export const signUp = data => {
  return {
    type: actionTypes.SIGNUP,
    data
  };
};

export const signUpSuccess = (data) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    data
  };
};

export const signUpFail = () => {
  return {
    type: actionTypes.SIGNUP_FAIL
  };
};


export const dismissRegistration = () => {
  return {
    type: actionTypes.DISMISS_REGISTER
  };
};

export const dismissRegistrationSuccess = () => {
  return {
    type: actionTypes.DISMISS_REGISTER_SUCCESS
  };
};

export const dismissFirstLogin = () => {
  return {
    type: actionTypes.DISMISS_FIRST_LOGIN
  };
};

export const dismissFirstLoginSuccess = () => {
  return {
    type: actionTypes.DISMISS_FIRST_LOGIN_SUCCESS
  };
};

export const clearFirstLoginErrMsg = () => {
  return {
    type: actionTypes.CLEAR_FIRST_LOGIN_ERRMSG
  };
};

export const clearFirstLoginErrMsgSuccess = () => {
  return {
    type: actionTypes.CLEAR_FIRST_LOGIN_ERRMSG_SUCCESS
  };
};


export const validateVersion = data => {
  return {
    type: actionTypes.VALIDATE_VERSION,
    data
  };
};

export const validateVersionSuccess = (data) => {
  return {
    type: actionTypes.VALIDATE_VERSION_SUCCESS,
    data
  };
};

export const validateVersionFail = () => {
  return {
    type: actionTypes.VALIDATE_VERSION_FAIL
  };
};




export const verifyAccount = data => {
  return {
    type: actionTypes.VERIFY_ACCOUNT,
    data
  };
};

export const verifyAccountSuccess = () => {
  return {
    type: actionTypes.VERIFY_ACCOUNT_SUCCESS,
  };
};

export const verifyAccountFail = () => {
  return {
    type: actionTypes.VERIFY_ACCOUNT_FAIL
  };
};


export const getAccountVerifyStatus = data => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFY_STATUS,
    data
  };
};

export const getAccountVerifyStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFY_STATUS_SUCCESS,
    data
  };
};

export const getAccountVerifyStatusFail = () => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFY_STATUS_FAIL
  };
};



export const logoutConfirmation = () => {
  return {
    type: actionTypes.LOGOUT_CONFIRMATION
  };
};

export const logout = (data) => {
  return {
    type: actionTypes.LOGOUT,
    data
  };
};
export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
};

export const logoutFail = () => {
  return {
    type: actionTypes.LOGOUT_FAIL
  };
};


export const refreshJWTToken = (data) => {
  return {
    type: actionTypes.REFRESH_JWT_TOKEN,
    data
  };
};

export const refreshJWTTokenSuccess = (token, refreshToken) => {
  return {
    type: actionTypes.REFRESH_JWT_TOKEN_SUCCESS,
    token,
    refreshToken
  };
};

export const refreshJWTTokenFail = () => {
  return {
    type: actionTypes.REFRESH_JWT_TOKEN_FAIL
  };
};

// Force logout
export const forceLogout = () => {
  return {
    type: actionTypes.FORCE_LOGOUT
  };
};

export const forceLogoutSuccess = data => {
  return {
    type: actionTypes.FORCE_LOGOUT_SUCCESS,
    data
  };
};
// Force logout

export const getMyProfile = (data) => {
  return {
    type: actionTypes.GET_PROFILE,
    data
  };
};

export const getMyProfileSuccess = data => {
  return {
    type: actionTypes.GET_PROFILE_SUCCESS,
    data
  };
};

export const getMyProfileFail = () => {
  return {
    type: actionTypes.GET_PROFILE_FAIL
  };
};


export const updateProfile = (data) => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    data
  };
};

export const updateProfileSuccess = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
  };
};

export const updateProfileFail = data => {
  return {
    type: actionTypes.UPDATE_PROFILE_FAIL,
    data
  };
};



export const uploadProfilePic = (data) => {
  return {
    type: actionTypes.UPLOAD_PROFILE_PIC,
    data
  };
};

export const uploadProfilePicSuccess = () => {
  return {
    type: actionTypes.UPLOAD_PROFILE_PIC_SUCCESS,
  };
};

export const uploadProfilePicFail = data => {
  return {
    type: actionTypes.UPLOAD_PROFILE_PIC_FAIL,
    data
  };
};


export const delProfilePic = (data) => {
  return {
    type: actionTypes.REMOVE_PROFILE_PIC,
    data
  };
};

export const delProfilePicSuccess = () => {
  return {
    type: actionTypes.REMOVE_PROFILE_PIC_SUCCESS
  };
};

export const delProfilePicFail = data => {
  return {
    type: actionTypes.REMOVE_PROFILE_PIC_FAIL,
    data
  };
};



export const updatePhone = (data) => {
  return {
    type: actionTypes.UPDATE_PHONE,
    data
  };
};

export const updatePhoneSuccess = () => {
  return {
    type: actionTypes.UPDATE_PHONE_SUCCESS,
  };
};

export const updatePhoneFail = data => {
  return {
    type: actionTypes.UPDATE_PHONE_FAIL,
    data
  };
};




export const checkVersion = (data) => {
  return {
    type: actionTypes.CHECK_VERSION,
    data
  };
};

export const checkVersionSuccess = (data) => {
  return {
    type: actionTypes.CHECK_VERSION_SUCCESS,
    data
  };
};

export const checkVersionFail = () => {
  return {
    type: actionTypes.CHECK_VERSION_FAIL
  };
};


export const versionUpdateList = (data) => {
  return {
    type: actionTypes.VERSION_UPDATE_LIST,
    data
  };
};

export const versionUpdateListSuccess = (data) => {
  return {
    type: actionTypes.VERSION_UPDATE_LIST_SUCCESS,
    data
  };
};

export const versionUpdateListFail = () => {
  return {
    type: actionTypes.VERSION_UPDATE_LIST_FAIL
  };
};

