/* ===== APP ===== */
export const COLLPSE_CHANGE = "COLLPSE_CHANGE";
export const COLLPSE_OPEN_DRAWER = "COLLPSE_OPEN_DRAWER";
export const CHANGE_OPEN_KEYS = "CHANGE_OPEN_KEYS";
export const TOGGLE_ALL = "TOGGLE_ALL";
export const CHANGE_CURRENT = "CHANGE_CURRENT";
export const CLOSE_ALL = "CLOSE_ALL";

export const SET_ENVIRONMENT = "SET_ENVIRONMENT";
export const SET_ENVIRONMENT_SUCCESS = "SET_ENVIRONMENT_SUCCESS";
export const SET_ENVIRONMENT_FAIL = "SET_ENVIRONMENT_FAIL";

export const RESET_APP_STATE = "RESET_APP_STATE";
export const NO_INTERNET = "NO_INTERNET";
export const ENABLE_LOADING = "ENABLE_LOADING";
export const DISABLE_LOADING = "DISABLE_LOADING";

export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_ERROR = "TOAST_ERROR";

export const MSG_SUCCESS = "MSG_SUCCESS";
export const MSG_ERROR = "MSG_ERROR";
export const MSG_WARNING = "MSG_WARNING";

export const LOAD_SECURE_IMAGE = "LOAD_SECURE_IMAGE";
export const LOAD_SECURE_IMAGE_SUCCESS = "LOAD_SECURE_IMAGE_SUCCESS";

export const HANDLE_API_ERROR = "HANDLE_API_ERROR";
export const FIELD_ERROR_HANDLE = "FIELD_ERROR_HANDLE";

/* ===== END APP ===== */

/* ===== AUTH ===== */
export const SET_DEVICE_UUID = "SET_DEVICE_UUID";

export const SET_UNDER_MAINTENANCE = "SET_UNDER_MAINTENANCE";
export const PROCEED_SIGNUP = "PROCEED_SIGNUP";
export const PROCEED_FIRST_LOGIN = "PROCEED_FIRST_LOGIN";


export const DEVICE_REGISTER = "DEVICE_REGISTER";
export const DEVICE_REGISTER_SUCCESS = "DEVICE_REGISTER_SUCCESS";
export const DEVICE_REGISTER_FAIL = "DEVICE_REGISTER_FAIL";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const DISMISS_REGISTER = "DISMISS_REGISTER";
export const DISMISS_REGISTER_SUCCESS = "DISMISS_REGISTER_SUCCESS";

export const DISMISS_FIRST_LOGIN = "DISMISS_FIRST_LOGIN";
export const DISMISS_FIRST_LOGIN_SUCCESS = "DISMISS_FIRST_LOGIN_SUCCESS";

export const CLEAR_FIRST_LOGIN_ERRMSG = "CLEAR_FIRST_LOGIN_ERRMSG";
export const CLEAR_FIRST_LOGIN_ERRMSG_SUCCESS = "CLEAR_FIRST_LOGIN_ERRMSG_SUCCESS";

export const SEND_SMS = "SEND_SMS";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_FAIL = "SEND_SMS_FAIL";

export const VERIFY_SMS = "VERIFY_SMS";
export const VERIFY_SMS_SUCCESS = "VERIFY_SMS_SUCCESS";
export const VERIFY_SMS_FAIL = "VERIFY_SMS_FAIL";
export const VERIFY_SMS_PENDING_APPROVAL = "VERIFY_SMS_PENDING_APPROVAL";

export const FIRST_LOGIN = "FIRST_LOGIN";
export const FIRST_LOGIN_SUCCESS = "FIRST_LOGIN_SUCCESS";
export const FIRST_LOGIN_FAIL = "FIRST_LOGIN_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const VALIDATE_VERSION = "VALIDATE_VERSION";
export const VALIDATE_VERSION_SUCCESS = "VALIDATE_VERSION_SUCCESS";
export const VALIDATE_VERSION_FAIL = "VALIDATE_VERSION_FAIL";

export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAIL = "VERIFY_ACCOUNT_FAIL";

export const GET_ACCOUNT_VERIFY_STATUS = "GET_ACCOUNT_VERIFY_STATUS";
export const GET_ACCOUNT_VERIFY_STATUS_SUCCESS = "GET_ACCOUNT_VERIFY_STATUS_SUCCESS";
export const GET_ACCOUNT_VERIFY_STATUS_FAIL = "GET_ACCOUNT_VERIFY_STATUS_FAIL";



export const LOGOUT_CONFIRMATION = "LOGOUT_CONFIRMATION";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPLOAD_PROFILE_PIC = "UPLOAD_PROFILE_PIC";
export const UPLOAD_PROFILE_PIC_SUCCESS = "UPLOAD_PROFILE_PIC_SUCCESS";
export const UPLOAD_PROFILE_PIC_FAIL = "UPLOAD_PROFILE_PIC_FAIL";

export const REMOVE_PROFILE_PIC = "REMOVE_PROFILE_PIC";
export const REMOVE_PROFILE_PIC_SUCCESS = "REMOVE_PROFILE_PIC_SUCCESS";
export const REMOVE_PROFILE_PIC_FAIL = "REMOVE_PROFILE_PIC_FAIL";

export const UPDATE_PHONE = "UPDATE_PHONE";
export const UPDATE_PHONE_SUCCESS = "UPDATE_PHONE_SUCCESS";
export const UPDATE_PHONE_FAIL = "UPDATE_PHONE_FAIL";

export const REFRESH_JWT_TOKEN = "REFRESH_JWT_TOKEN";
export const REFRESH_JWT_TOKEN_SUCCESS = "REFRESH_JWT_TOKEN_SUCCESS";
export const REFRESH_JWT_TOKEN_FAIL = "REFRESH_JWT_TOKEN_FAIL";

export const FORCE_LOGOUT = "FORCE_LOGOUT";
export const FORCE_LOGOUT_SUCCESS = "FORCE_LOGOUT_SUCCESS";


export const CHECK_VERSION = "CHECK_VERSION";
export const CHECK_VERSION_SUCCESS = "CHECK_VERSION_SUCCESS";
export const CHECK_VERSION_FAIL = "CHECK_VERSION_FAIL";

export const VERSION_UPDATE_LIST = "VERSION_UPDATE_LIST";
export const VERSION_UPDATE_LIST_SUCCESS = "VERSION_UPDATE_LIST_SUCCESS";
export const VERSION_UPDATE_LIST_FAIL = "VERSION_UPDATE_LIST_FAIL";
/* ===== END AUTH ===== */



/* ===== GOOGLE PLACE ===== */
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE";
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS";
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL";
/* ===== END GOOGLE PLACE ===== */



/* ===== GENERAL ===== */
export const LOAD_STATE_DISTRICT_LIST = "LOAD_STATE_DISTRICT_LIST";
export const LOAD_STATE_DISTRICT_LIST_SUCCESS = "LOAD_STATE_DISTRICT_LIST_SUCCESS";
export const LOAD_STATE_DISTRICT_LIST_FAIL = "LOAD_STATE_DISTRICT_LIST_FAIL";


/* ===== ORDER  ===== */
export const GENERATE_USER_QR = "GENERATE_USER_QR";
export const GENERATE_USER_QR_SUCCESS = "GENERATE_USER_QR_SUCCESS";
export const GENERATE_USER_QR_FAIL = "GENERATE_USER_QR_FAIL";

export const RECENT_EXPRESS_ORDER = "RECENT_EXPRESS_ORDER";
export const RECENT_EXPRESS_ORDER_DUPLICATE_DATA = "RECENT_EXPRESS_ORDER_DUPLICATE_DATA";
export const RECENT_EXPRESS_ORDER_SUCCESS = "RECENT_EXPRESS_ORDER_SUCCESS";
export const RECENT_EXPRESS_ORDER_FAIL = "RECENT_EXPRESS_ORDER_FAIL";

export const EXPRESS_ORDER_DETAIL = "EXPRESS_ORDER_DETAIL";
export const EXPRESS_ORDER_DETAIL_DUPLICATE_DATA = "EXPRESS_ORDER_DETAIL_DUPLICATE_DATA";
export const EXPRESS_ORDER_DETAIL_SUCCESS = "EXPRESS_ORDER_DETAIL_SUCCESS";
export const EXPRESS_ORDER_DETAIL_FAIL = "EXPRESS_ORDER_DETAIL_FAIL";

export const EXPRESS_CONFIRM_ORDER = "EXPRESS_CONFIRM_ORDER";
export const EXPRESS_CONFIRM_ORDER_SUCCESS = "EXPRESS_CONFIRM_ORDER_SUCCESS";
export const EXPRESS_CONFIRM_ORDER_FAIL = "EXPRESS_CONFIRM_ORDER_FAIL";
/* ===== END ORDER ===== */


