import { all, fork, takeEvery, put, select } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/index";
//import * as API from "../api";
//import { string } from "prop-types";
const getState = (state) => ( state )

export function* handleAPIError() {
  yield takeEvery(actionTypes.HANDLE_API_ERROR, function*(action) {
    let error = action.error;

    const state_data = yield select(getState);

    if (error.response && error.response.status && error.response.data && !error.response.data.duplicate) {

      if(error.response.data.force_logout)
      {
        if(state_data.Auth.isLoggedIn && !state_data.Auth.isProcessLogout)
        {
          yield put(actions.toastError(error.response.data.message));
          yield put(actions.logout());
        }

      }else if (error.response.status !== 401) {

        if(typeof error.response.data.message !== "string")
          yield put(actions.fieldErrorHandle( error.response.data.message ));

        else{

          // alert(
          //   "Error: " +
          //     (action.message !== "" ? action.message + "\n" : "") +
          //     API.constructErrMsg(action.error)
          // );
          yield put(actions.toastError(error.response.data.message));
          
        }
      }else if(!error.response.data.message.includes("jwt expired")){
        
        yield put(actions.toastError(error.response.data.message));
      }
    } else {
      console.log("Handle api error:", error);
    }
    yield put(actions.disableLoading());
  });
}
// Combine all sage
export default function* appSaga() {
  yield all([fork(handleAPIError)]);
}
