import * as actionTypes from "./actionTypes";


export const loadStateDistrictList = (data) => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST
  };
};

export const loadStateDistrictListSuccess = data => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST_SUCCESS,

    data
  };
};
