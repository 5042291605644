import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import SignIn from "./containers/Page/SignIn";
import UnderMaintenance from "./containers/Page/UnderMaintenance";
import FirstLogin from "./containers/Page/FirstLogin";

const browserHistory = createBrowserHistory();


const RestrictedRoute = ({ component: Component, isLoggedIn, isUnderMaintenance, proceedFirstLogin, ...rest }) => (
  
  <Route
    {...rest}
    render={props =>
      isUnderMaintenance ? ( <UnderMaintenance /> ): (
      
        <React.Fragment>
          {isLoggedIn ? ( <Component {...props} /> ) : (

            <React.Fragment>
              {proceedFirstLogin ? ( <FirstLogin /> ) : (

                <SignIn />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    }
  />
);

const PublicRoutes = ({ history, isDeviceRegistered, isLoggedIn, isUnderMaintenance, proceedFirstLogin }) => {
  
  let routes = (
    <Route
      exact
      path={"/"}
      component={asyncComponent(() => import("./containers/Page/LoadingPage"))}
    />
  );

  console.log("PublicRoutes", {isDeviceRegistered, isLoggedIn, isUnderMaintenance, proceedFirstLogin})

  if(isDeviceRegistered || isUnderMaintenance){
    
    routes = (
      <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} isUnderMaintenance={isUnderMaintenance} proceedFirstLogin={proceedFirstLogin} />
    );
  }

  return (
    <Router history={browserHistory}>
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            {routes}
            <Redirect to="/" />
          </Switch>
        </div>
      </ConnectedRouter>
    </Router>
  );
};

const mapStateToProps = state => {
  
  return {
    isDeviceRegistered: state.Auth.isDeviceRegistered,
    isLoggedIn: state.Auth.isLoggedIn,
    isUnderMaintenance: state.Auth.isUnderMaintenance,
    proceedFirstLogin: state.Auth.proceedFirstLogin,
  };
};

export default connect(mapStateToProps)(PublicRoutes);
