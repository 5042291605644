import * as actionTypes from "./actionTypes";


export const generateQR = data => {
  
  return {
    type: actionTypes.GENERATE_USER_QR,
    data
  };
};

export const generateQRSuccess = data => {
  
  return {
    type: actionTypes.GENERATE_USER_QR_SUCCESS,
    data
  };
};

export const generateQRFail = () => {
  return {
    type: actionTypes.GENERATE_USER_QR_FAIL
  };
};


export const recentExpressOrder = (data) => {
  
  return {
    type: actionTypes.RECENT_EXPRESS_ORDER,
    data
  };
};

export const recentExpressOrderUsePrevious = () => {
  
  return {
    type: actionTypes.RECENT_EXPRESS_ORDER_DUPLICATE_DATA
  };
};

export const recentExpressOrderSuccess = data => {
  
  return {
    type: actionTypes.RECENT_EXPRESS_ORDER_SUCCESS,
    data
  };
};

export const recentExpressOrderFail = () => {
  return {
    type: actionTypes.RECENT_EXPRESS_ORDER_FAIL
  };
};



export const expressOrderDetail = (id, data) => {
  
  return {
    type: actionTypes.EXPRESS_ORDER_DETAIL,
    id,
    data
  };
};

export const expressOrderDetailUsePrevious = () => {
  
  return {
    type: actionTypes.EXPRESS_ORDER_DETAIL_DUPLICATE_DATA
  };
};


export const expressOrderDetailSuccess = data => {
  
  return {
    type: actionTypes.EXPRESS_ORDER_DETAIL_SUCCESS,
    data
  };
};

export const expressOrderDetailFail = () => {
  return {
    type: actionTypes.EXPRESS_ORDER_DETAIL_FAIL
  };
};




export const expressConfirmOrder = (id, data) => {
  
  return {
    type: actionTypes.EXPRESS_CONFIRM_ORDER,
    id,
    data
  };
};

export const expressConfirmOrderSuccess = data => {
  
  return {
    type: actionTypes.EXPRESS_CONFIRM_ORDER_SUCCESS,
    data
  };
};

export const expressConfirmOrderFail = () => {
  return {
    type: actionTypes.EXPRESS_CONFIRM_ORDER_FAIL
  };
};
