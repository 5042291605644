import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

export function* generateQR() {
  yield takeEvery(actionTypes.GENERATE_USER_QR, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GENERATEUSERQR");    
    action.data.track_no = track_no
    
    try {
      const response = yield API.generateQR(action.data);
      if(response)
      {
        if (response.data.success) {

          yield put(actions.generateQRSuccess());
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.generateQRFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.generateQRFail());
    }
  });
}


export function* recentExpressOrder() {
  yield takeEvery(actionTypes.RECENT_EXPRESS_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECENTEXPRESSORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.recentExpressOrder(action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield put(actions.recentExpressOrderSuccess(response.data.data));
          
        } else {
          
          yield put(actions.msgError(response.data.message));
          yield put(actions.recentExpressOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      // yield put(actions.disableLoading());

      if(error.response.data.duplicate)
        yield put(actions.recentExpressOrderUsePrevious());

      else{

        yield put(actions.handleAPIError(error));
        yield put(actions.recentExpressOrderFail());
      }
      
    }
  });
}


export function* expressOrderDetail() {
  yield takeEvery(actionTypes.EXPRESS_ORDER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSORDERDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.expressOrderDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield put(actions.expressOrderDetailSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.expressOrderDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      // yield put(actions.disableLoading());

      if(error.response.data.duplicate)
        yield put(actions.expressOrderDetailUsePrevious());

      else{
        
        yield put(actions.handleAPIError(error));
        yield put(actions.expressOrderDetailFail());
      }
    }
  });
}


export function* expressConfirmOrder() {
  yield takeEvery(actionTypes.EXPRESS_CONFIRM_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSCONFIRMORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.expressConfirmOrder(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          yield delay(1000);
          yield put(actions.expressConfirmOrderSuccess());
          
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.expressConfirmOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.expressConfirmOrderFail());
    }
  });
}



export default function* orderSaga() {
  
  yield all([fork(generateQR)]);
  yield all([fork(recentExpressOrder)]);
  yield all([fork(expressOrderDetail)]);
  yield all([fork(expressConfirmOrder)]);
}
