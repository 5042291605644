import Auth from "./auth_reducer";
import App from "./app_reducer";
import GoogleMap from "./google_map_reducer";
import General from "./general_reducer";
import Order from "./order_reducer";

export default {
  Auth,
  App,
  GoogleMap,
  General,
  Order,
};