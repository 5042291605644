import * as actionTypes from "./actionTypes";
import Modals from "../../components/feedback/modal";
import message from "../../components/feedback/message";

export function getView(width) {
  let newView = "MobileView";
  if (width > 1220) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}

export const toggleCollapsed = () => {
  return {
    type: actionTypes.COLLPSE_CHANGE
  };
};

export const toggleOpenDrawer = () => {
  return {
    type: actionTypes.COLLPSE_OPEN_DRAWER
  };
};

export const changeOpenKeys = openKeys => {
  return {
    type: actionTypes.CHANGE_OPEN_KEYS,
    openKeys
  };
};

export const toggleAll = (width, height) => {
  const view = getView(width);
  const collapsed = view !== "DesktopView";
  return {
    type: actionTypes.TOGGLE_ALL,
    collapsed,
    view,
    height
  };
};

export const changeCurrent = current => {
  return {
    type: actionTypes.CHANGE_CURRENT,
    current
  };
};

export const closeAll = () => {
  return {
    type: actionTypes.CLOSE_ALL
  };
};

export const setEnvironment = env => {
  return {
    type: actionTypes.SET_ENVIRONMENT,
    env
  };
};

export const setEnvironmentSuccess = () => {
  return {
    type: actionTypes.SET_ENVIRONMENT_SUCCESS
  };
};

export const setEnvironmentFail = error => {
  return {
    type: actionTypes.SET_ENVIRONMENT_FAIL,
    error
  };
};

export const enableLoading = () => {
  return {
    type: actionTypes.ENABLE_LOADING
  };
};

export const disableLoading = () => {
  return {
    type: actionTypes.DISABLE_LOADING
  };
};

export const toastSuccess = (message, title = null) => {
  return dispatch => {
    dispatch({ type: actionTypes.TOAST_SUCCESS });

    Modals.success({
      title: (title) ? title : message,
      content: (title) ? message : null,
      okText: "OK",
    });
  };
};

export const toastError = message => {
  return dispatch => {
    dispatch({ type: actionTypes.TOAST_ERROR });

    Modals.error({
      title: "Error",
      content: message,
      okText: "OK",
    });
  };
};


export const msgSuccess = (msg) => {
  return dispatch => {
    dispatch({ type: actionTypes.MSG_SUCCESS });

    message.success(msg, 3);
  };
};


export const msgError = (msg) => {
  return dispatch => {
    dispatch({ type: actionTypes.MSG_ERROR });

    message.error(msg, 3);
  };
};


export const msgWarning = (msg) => {
  return dispatch => {
    dispatch({ type: actionTypes.MSG_WARNING });

    message.warning(msg, 3);
  };
};



export const handleAPIError = (error, message = "") => {
  return {
    type: actionTypes.HANDLE_API_ERROR,
    error,
    message
  };
};


export const fieldErrorHandle = message => {

  return {
    type: actionTypes.FIELD_ERROR_HANDLE,
    message
  };
};

