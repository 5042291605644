import axios from "axios";
import { isString, isArray, each } from "lodash";
import { API_HOST, APP_ENV } from "../../settings";
import { store } from "../store";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as api from "../api";
import Helper from "../../helpers/helpers";

let isAlreadyFetchingAccessToken = false
let subscribers = []

export const APIHost = () => {
  // let env = store.getState().App.env;
  let env = APP_ENV
  
  let host = env !== null ? API_HOST[env] : API_HOST["Live"];
  return host;
};

export const SetAxiosAuthBearer = jwt_bearer => {
  axios.defaults.headers.common["Authorization"] = jwt_bearer;
};

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export const SetAxiosDefaultConfig = () => {
  if (store.getState().Auth.jwtToken !== null) {
    SetAxiosAuthBearer(store.getState().Auth.jwtToken);
  }
  axios.defaults.timeout = 25000;
  axios.interceptors.response.use(
    response => {
      if (store.getState().App.app_connection === false) {
        store.dispatch({
          type: actionTypes.RESET_APP_STATE
        });
      }
      
      if(Object.keys(response.data).includes("data") && response.data.data && response.data.data.under_maintenance)
      {
        store.dispatch(actions.underMaintenance());

      }else
        return response;
    },
    error => {
      // Capture network error (no internet connection and etc)
      if (error.request && error.request._hasError) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          console.log("request error");
          console.log(error.request);
        }

        if (
          error.request._response &&
          error.request._response ===
            "The Internet connection appears to be offline."
        ) {
          store.dispatch({
            type: actionTypes.NO_INTERNET
          });
        }

        return Promise.reject({
          response: { data: { message: "Network Problem. Please Retry" } }
        });
      }

      if (error.response && error.response.config) {
        const ori_request = error.response.config;
        const { Auth } = store.getState();
        
        // Timeout & retry
        if (
          (error.code && error.code === "ECONNABORTED") ||
          (error.response && error.response.status === 408)
        ) {
          if (typeof ori_request._retry_timeout === "undefined") {
            ori_request._retry_timeout = true;
            return axios(ori_request);
          }
        }
        
        // Auth Token Invalid (re-auth & retry)
        else if (error.response.status === 401 && !error.response.data.message.includes("Unauthorized") && !error.response.data.force_logout) {
          
          if(error.response.data.force_logout) //Move checking to app_saga.js
          {
            console.log("Force Logout")
            if(store.getState().Auth.isLoggedIn && !store.getState().Auth.isProcessLogout)
              store.dispatch(actions.logout());

          }else if(ori_request.url.includes("/token") && error.response.data.message.includes("jwt expired") )
          {
            console.log("Refesh Token Bearer Expired")
            if(store.getState().Auth.isLoggedIn && !store.getState().Auth.processDeviceRegistered)
            {             
              store.dispatch(actions.toastError("Your session has expired. Please relogin"));
              store.dispatch(actions.deviceRegister({logout: true}));
            }
          }else{

            let track_no = Helper.generateTrackNo("REFRESHJWTTOKEN");
  
            console.log("Process Refresh Token", {isAlreadyFetchingAccessToken, jwtTokenRefresh: Auth.jwtTokenRefresh})
  
            if (!isAlreadyFetchingAccessToken) {
              
              if(Auth.jwtTokenRefresh)
              {
                isAlreadyFetchingAccessToken = true
                SetAxiosAuthBearer(Auth.jwtTokenRefresh);
    
                api
                .refreshJWTToken({
                  user_id: Auth.profileData.user_id,
                  track_no
                }).then(res => {
    
                  console.log("Refresh Token Processed", res.data)
                  isAlreadyFetchingAccessToken = false
                  
                  if (res.data.success && res.data.token && res.data.refresh_token) {
                    SetAxiosAuthBearer(res.data.token);
    
                    console.log("Refresh Token Success")
                    store.dispatch({
                      type: actionTypes.REFRESH_JWT_TOKEN_SUCCESS,
                      token: res.data.token,
                      refresh_token: res.data.refresh_token
                    });
                    
                    onAccessTokenFetched(res.data.token)
    
                  } else {
                    console.log("Refresh Token Return Fail")
                    store.dispatch(actions.logout());
                  }
                })
  
              }else{
                console.log("No JWT Refresh Token")
                store.dispatch(actions.logout());
              }
  
            }else if(ori_request.url.includes("admin/token"))
            {
              console.log("Refresh Token Recall Second Time")
              store.dispatch(actions.logout());
            }          
  
            console.log(666, "end")
            
  
            const retryOriginalRequest = new Promise((resolve) => {
              addSubscriber(access_token => {
  
                ori_request.headers.Authorization = access_token
                store.dispatch(actions.disableLoading());
  
                resolve(axios(ori_request))
              })
            })
            return retryOriginalRequest
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export const constructErrMsg = error => {
  let err_msg = "Error";

  if (error.response && error.response.data && error.response.data.message) {
    if (isString(error.response.data.message))
      err_msg = error.response.data.message;
    else if (isArray(error.response.data.message)) {
      err_msg += ":";

      each(error.response.data.message, val => {
        err_msg += "\n[" + val.msg + "]";
      });
    }
  }

  return err_msg;
};
