import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";


export function* loadStateDistrictList() {

  yield takeEvery(actionTypes.LOAD_STATE_DISTRICT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STATEDISTRICTLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadStateDistrictList( action.state, action.district, action.data );
      if(response)
      {
        if (response.data.success) {
          yield put(actions.loadStateDistrictListSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
    }
  });
}


export default function* generalSaga() {
  
  yield all([fork(loadStateDistrictList)]);
}
