import axios from "axios";
import moment from "moment";
import * as loadImage from "blueimp-load-image";
import { store } from "../redux/store";

const helpers = {
  /* Convert number to 2 decimal without round up */
  toFixedTwoWithoutRoundUp: (value, callback) => {
    let length = String(Number.parseFloat(value).toFixed(6)).length;

    return String(Number.parseFloat(value).toFixed(6)).substring(0, length - 4);
  },
  loadSecureImage: (url, callback) => {
    return axios
      .get(url, { responseType: "blob" })
      .then(response => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          let file = new File([response.data], "image.png", {
            type: "image/png"
          });
          callback(reader.result, file);
        });
        reader.readAsDataURL(response.data);
      })
      .catch(error => {
        return false;
      });
  },
  loadSecureFile: (url, callback) => {
    return axios
      .get(url, { responseType: "blob" })
      .then(response => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          callback(reader.result, response.data);
        });
        reader.readAsDataURL(response.data);
      })
      .catch(error => {
        return false;
      });
  },
  generateTrackNo: prefix => {

    let profileData = store.getState().Auth.profileData  
    let uuid = store.getState().Auth.uuid     
    // let text = "";
    let now = moment().format("YYYYMMDDHHmmss");
    // let possible =
    //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    // for (var i = 0; i < 16; i++) {
    //   text += possible.charAt(Math.floor(Math.random() * possible.length));
    // }

    let trackNo = `${(profileData) ? profileData.user_id : uuid}${prefix}${now}`;

    return trackNo;
  },
  convertTobase64: (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      callback(reader.result);
    });
    reader.readAsDataURL(file);
  },
  getDateRange: () => {
    let dateRange = {
      Today: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      Yesterday: [
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ],
      "Last 7 days": [
        moment()
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ],
      "Last 30 days": [
        moment()
          .subtract(29, "days")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ],
      "This Month": [
        moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      ],
      "Last Month": [
        moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD")
      ]
    };

    return dateRange;
  },
  loadImageWithRotation: (file, callback) => {
    return new Promise((resolve, reject) => {
      loadImage(
        file,
        async canvas => {
          const imageBaseURL = canvas.toDataURL();
          const newFile = await helpers.convertCanvasToBlob(canvas, file);

          let data = {
            file: newFile,
            image: imageBaseURL
          };
          resolve(data);
        },
        { orientation: true }
      );
    });
  },
  convertCanvasToBlob: (canvas, file) => {
    let fileName = "image";
    let fileType = "image/jpeg";

    if (file) {
      fileName = file.name;
      fileType = file.type;
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        const file = new File([blob], fileName, {
          type: fileType
        });
        resolve(file);
      }, "image/jpeg");
    });
  },
  generateRandCode: (length, type) => {
    var text = "";
    var possible = "";

    // Type
    // number      =  No
    // character   = Character
    // mix         =  Character Capital&SmallLetter with no
    // charBigNo   = Character Capital with no
    // charSmallNo = Character Small letter with no

    switch (type) {
      case "number":
        possible = "0123456789";
        break;
      case "character":
        possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        break;
      case "charBigNo":
        possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        break;
      case "charSmallNo":
        possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        break;
      default:
        possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        break;
    }

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
};

export default helpers;
