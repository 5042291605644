import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/grader";

  // return "http://192.168.1.12:3003/general/grader"
};


export const loadStateDistrictList = (state, district, data) => {
  const url =
    state === undefined && district === undefined
      ? `${baseURL()}/state`
      : state !== undefined && district === undefined
      ? `${baseURL()}/state/${state}`
      : state !== undefined && district !== undefined
      ? `${baseURL()}/state/${state}/${district}`
      : null;

  return axios.post(url, data);
};
