import * as actionTypes from "../actions/actionTypes";

const initState = {
  stateDistrictList: [],
  isStateDistrictList: false,
};

export default function generalReducer(state = initState, action) {
 
  switch (action.type) {
   
    case actionTypes.LOAD_STATE_DISTRICT_LIST: {
      return {
        ...state,
        stateDistrictList: [],
        isStateDistrictList: true,
      };
    }

    case actionTypes.LOAD_STATE_DISTRICT_LIST_SUCCESS: {
      return {
        ...state,
        stateDistrictList: action.data,
        isStateDistrictList: false,

      };
    }

    case actionTypes.LOAD_STATE_DISTRICT_LIST_FAIL: {
      return {
        ...state,
        isStateDistrictList: false,

      };
    }
    
    
    
    default:
      return state;
  }
}
