import { all, takeEvery, put, fork, select } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/index";
import * as API from "../api";
// import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

import { APP_VERSION } from "../../settings";
const uuidv4 = require("uuid/v4");
const getState = (state) => ( state )

export function* deviceRegister() {
  
  yield takeEvery(actionTypes.DEVICE_REGISTER, function*(action) {
    
    let track_no = Helper.generateTrackNo("DEVICEREGISTER");

    // yield put(actions.enableLoading());

    try {
      const state_data = yield select(getState);

      let uuid = (state_data && state_data.Auth && state_data.Auth.uuid) ? state_data.Auth.uuid : uuidv4()

      let params = {
        uuid,
        app_token: "GRADER-WEB",
        language: "en",
        track_no
      };
      
      const response = yield API.deviceRegister(params);
      
      if(response)
      {
        if (response.data.success && response.data.data) {

          yield API.SetAxiosAuthBearer(response.data.token);

          if(action.data && action.data.logout)
          {
            yield put(actions.logout());

          }else if(response.data.data.under_maintenance)
          {            
            yield put(actions.underMaintenance());
            
          }else{
            yield put(actions.deviceRegisterSuccess(response.data));
            yield put(actions.checkVersion());
          }

        } else {
          yield put(actions.msgError("Warning: Fail to register device. " + response.data.message));
        }

        yield put(actions.update_device_uuid(uuid));
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      
      yield put(actions.underMaintenance());
      // yield put(actions.msgError("Error: Fail to register device. " + API.constructErrMsg(error)));
      // yield put(actions.disableLoading());
    }
  });
}

export function* sendSMS() {
  
  yield takeEvery(actionTypes.SEND_SMS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SENDSMS");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.sendSMS(action.data);

      if(response)
      {
        if (response.data.success) {
          
          yield put(actions.sendSMSSuccess(response.data));
        } else {
          
          yield put(actions.sendSMSFail(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      // yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.sendSMSFail(error.response.data.message));
    }
  });
}



export function* verifySMS() {
  
  yield takeEvery(actionTypes.VERIFY_SMS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VERIFYSMS");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.verifySMS(action.data);

      if(response)
      {
        if(Object.keys(response.data).includes("data") && response.data.data.go_register)
        {
          // yield put(actions.proceedSignUp(action.data));
          
          yield put(actions.verifySMSFail(response.data.message));
          
        }
        else if(Object.keys(response.data).includes("data") && response.data.data.go_first_login)
        {
          yield put(actions.proceedFirstLogin(action.data));
          
        }
        else if (response.data.success) {

          if(response.data.data.pending_approval)
          {
            yield put(actions.verifySMSPendingApproval(response.data.data));

          }else if(response.data.data.user_id && response.data.data.user_id !== null)
          {          
            yield API.SetAxiosAuthBearer(response.data.token);
            yield put(actions.loginSuccess(response.data.token, response.data.refresh_token, response.data.data));

          }else
            yield put(actions.verifySMSSuccess(response.data.data));

        } else {
          
          yield put(actions.verifySMSFail(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      // yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.verifySMSFail(error.response.data.message));
    }
  });
}



export function* firstLogin() {
  
  yield takeEvery(actionTypes.FIRST_LOGIN, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FIRSTLOGIN");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.firstLogin(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          yield API.SetAxiosAuthBearer(response.data.token);
          yield put(actions.firstLoginSuccess(response.data.token, response.data.refresh_token, response.data.data));
        } else {
          
          yield put(actions.msgError(response.data.message));
          yield put(actions.firstLoginFail(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      // yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.firstLoginFail(error.response.data.message));
    }
  });
}


export function* signUp() {
  
  yield takeEvery(actionTypes.SIGNUP, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SIGNUP");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.signUp(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.signUpSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.signUpFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.signUpFail());
    }
  });
}

export function* dismissRegistration() {
  
  yield takeEvery(actionTypes.DISMISS_REGISTER, function*(action) {
    
    yield put(actions.dismissRegistrationSuccess());
  });

}

export function* dismissFirstLogin() {
  
  yield takeEvery(actionTypes.DISMISS_FIRST_LOGIN, function*(action) {
    
    yield put(actions.dismissFirstLoginSuccess());
  });

}

export function* clearFirstLoginErrMsg() {
  
  yield takeEvery(actionTypes.CLEAR_FIRST_LOGIN_ERRMSG, function*(action) {
    
    yield put(actions.clearFirstLoginErrMsgSuccess());
  });

}



export function* validateVersion() {
  
  yield takeEvery(actionTypes.VALIDATE_VERSION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VALIDATEVERSION");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.validateVersion(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.validateVersionSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.validateVersionFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.validateVersionFail());
    }
  });
}


export function* verifyAccount() {
  
  yield takeEvery(actionTypes.VERIFY_ACCOUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VERIFYACCOUNT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.verifyAccount(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.verifyAccountSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.verifyAccountFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.verifyAccountFail());
    }
  });
}


export function* getAccountVerifyStatus() {
  
  yield takeEvery(actionTypes.GET_ACCOUNT_VERIFY_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETVERIFYSTATUS");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getAccountVerifyStatus(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.getAccountVerifyStatusSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getAccountVerifyStatusFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getAccountVerifyStatusFail());
    }
  });
}





// export function* firstLogin() {
//   yield takeEvery(actionTypes.FIRST_LOGIN, function*(action) {
//     try {
//       const response = yield API.sendSMS(action.data);

//       if (response.data.success && response.data.token && response.data.refresh_token) {
//         yield API.SetAxiosAuthBearer(response.data.token);
//         yield put(
//           actions.sendSMSSuccess(response.data.token, response.data.refresh_token, response.data.data)
//         );
//       } else {
//         yield put(actions.sendSMSFail(response.data.message));
//       }
//     }catch (error) {
//       yield put(actions.handleAPIError(error));
//       yield put(actions.disableLoading());
//       yield put(actions.sendSMSFail());
//     } 
//   });
// }



// export function* login() {
//   yield takeEvery(actionTypes.LOGIN, function*(action) {
//     try {
//       const response = yield API.login(action.data);

//       if (response.data.success && response.data.token && response.data.refresh_token) {
//         yield API.SetAxiosAuthBearer(response.data.token);
//         yield put(
//           actions.loginSuccess(response.data.token, response.data.refresh_token, response.data.data)
//         );
//       } else {
//         yield put(actions.loginError(response.data.message));
//       }
//     } catch (error) {
//       yield put(actions.loginError(API.constructErrMsg(error)));
//     }
//   });
// }

// export function* loginError() {
//   yield takeEvery(actionTypes.LOGIN_FAIL, function*(action) {
//     yield alert(action.error);
//   });
// }

export function* logout() {
  yield takeEvery(actionTypes.LOGOUT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LOGOUT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    try {
      const response = yield API.logout(action.data);
      if(response)
      {        
        if (response.data.success) {
          yield put(actions.logoutSuccess());        
          yield put(actions.deviceRegister());
          // yield put(push("/"));
        } else {
          yield put(actions.msgError("Failed to logout!" + response.data.message));
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error, "Failed to logout!"));
      // yield put(actions.disableLoading());
      yield put(actions.logoutFail());
    }
  });
}


export function* logoutRequest() {
  yield takeEvery(actionTypes.LOGOUT_CONFIRMATION, function*(action) {
    const confirm = window.confirm("Are you sure want to log out?");

    if (confirm) {
      yield put(actions.logout());
    }
  });
}

// Relogin
export function* refreshJWTToken() {
  
  yield takeEvery(actionTypes.REFRESH_JWT_TOKEN, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REFRESHJWTTOKEN");
    action.data.track_no = track_no

    try {
      const response = yield API.refreshJWTToken(action.data);
      if(response)
      {
        if (response.data.success && response.data.token && response.data.refresh_token) {
          yield API.SetAxiosAuthBearer(response.data.token);
          yield put(
            actions.refreshJWTTokenSuccess(
              response.data.token,
              response.data.data
            )
          );
        } else {
          console.log("Failed to refresh JWT Token!" + response.data.message)
          // yield put(actions.msgError("Failed to refresh JWT Token!" + response.data.message));
          yield put(actions.logout());
        }
      }
    } catch (error) {
      console.log("Failed to refresh JWT Token!" + error)
      // yield put(actions.handleAPIError(error, "Failed to refresh JWT Token!"));
      yield put(actions.logout());
    }
  });
}

export function* forceLogout() {
  yield takeEvery(actionTypes.FORCE_LOGOUT, function*() {
    yield put(actions.enableLoading());
    yield put(actions.forceLogoutSuccess());
    yield put(actions.disableLoading());
  });
}

export function* getMyProfile() {
  yield takeEvery(actionTypes.GET_PROFILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETPROFILE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getMyProfile(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          yield put(actions.getMyProfileSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
    }
  });
}

export function* updateProfile() {
  yield takeEvery(actionTypes.UPDATE_PROFILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDATEPROFILE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.updateProfile(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.updateProfileSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateProfileFail());
    }
  });
}

export function* uploadProfilePic() {
  yield takeEvery(actionTypes.UPLOAD_PROFILE_PIC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPLOADPROFILEPIC");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.uploadProfilePic(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.uploadProfilePicSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.uploadProfilePicFail());
    }
  });
}

export function* delProfilePic() {
  yield takeEvery(actionTypes.REMOVE_PROFILE_PIC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEPROFILEPIC");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.delProfilePic(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.delProfilePicSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.delProfilePicFail());
    }
  });
}


export function* checkVersion() {
  yield takeEvery(actionTypes.CHECK_VERSION, function*(action) {

    if(!action.data) action.data = {app_version: APP_VERSION}

    let track_no = Helper.generateTrackNo("CHECKVERSION");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.checkVersion(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          yield put(actions.checkVersionSuccess(response.data.data));
        } else {
          // alert("New content is available. Please refresh (Shift + F5).");
          yield put(actions.checkVersionFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.checkVersionFail());
    }
  });
}


export function* versionUpdateList() {
  yield takeEvery(actionTypes.VERSION_UPDATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VERSIONUPLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.versionUpdateList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          yield put(actions.versionUpdateListSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.versionUpdateListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.versionUpdateListFail());
    }
  });
}



export default function* authSaga() {
  yield all([
    
    fork(deviceRegister),
    fork(sendSMS),
    fork(verifySMS),
    fork(firstLogin),

    fork(signUp),
    fork(dismissRegistration),
    fork(dismissFirstLogin),
    fork(clearFirstLoginErrMsg),
    
    fork(validateVersion),
    fork(verifyAccount),
    fork(getAccountVerifyStatus),
    
    // fork(login),
    // fork(loginError),
    fork(refreshJWTToken),
    fork(logout),
    fork(logoutRequest),
    fork(forceLogout),
    fork(getMyProfile),
    fork(updateProfile),
    fork(uploadProfilePic),
    fork(delProfilePic),
    fork(checkVersion),
    fork(versionUpdateList)
  ]);
}
